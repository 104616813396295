@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
  0 - 600px:      Phone
  600 - 900px:    Tablet portrait
  900 - 1280px:   Tablet landscape
  [1280 - 1600] is where our normal styles apply
  1920px:      desktop
  1920px+:     big desktop
  $breakpoint arguement choices:
  - phone
  - tab-port
  - tab-land
  - laptop
  - desktop
  - big-desktop
  ORDER: Base + typography > general layout + grid > page layout > components
  1em = 16px
  */
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 80em) {
      @content;
    } //1280px
  }
  @if $breakpoint == laptop {
    @media only screen and (max-width: 100em) {
      @content;
    } //1600px
  }
  @if $breakpoint == desktop {
    @media only screen and (max-width: 120em) {
      @content;
    } //1920px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 120.0625em) {
      @content;
    } //1920px+
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
