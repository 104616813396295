.e-green-color {
  color: $e-green-color;
}

.e-red-color {
  color: $e-red-color;
}

.e-blue-color {
  color: $e-blue-color;
}

.e-pink-color {
  color: $e-pink-color;
}

.e-green-color-light {
  color: lighten($e-green-color, 10%);
}

.e-red-color-light {
  color: lighten($e-red-color, 10%);
}

.e-blue-color-light {
  color: lighten($e-blue-color, 10%);
}

.e-pink-color-light {
  color: lighten($e-pink-color, 10%);
}
